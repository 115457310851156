















































































/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import PortfolioModal from "@/components/PortfolioModal.vue";

interface PortfolioItem {
  id: number;
  new: boolean;
  url: string;
  img: string;
  imgFull: string;
  title: string;
  description: string;
  cta: string;
}

interface PortfolioItems {
  [index: number]: PortfolioItem;
}

interface Portfolio {
  [key: string]: PortfolioItems;

  all: any;
  mc: any;
  va: any;
}

@Component({
  components: {
    PortfolioModal,
  },
})
export default class MainHeader extends Vue {
  @Prop() private anchorLink!: string;

  private activePortfolio = "all";

  private $modal: any;

  private activeItem: Array<object> = [];

  private portfolioItems: PortfolioItems = [
    {
      id: 0,
      new: false,
      url: "#",
      img: "img/folio/default.jpg",
      imgFull: "img/folio/default-portrait.jpg",
      title: "Loading...",
      description: "Loading...",
      cta: "Loading...",
    },
  ];

  private portfolio: Portfolio = {
    all: [],
    mc: [
      {
        id: 1,
        new: true,
        url: "#",
        img: "img/folio/dog-loves-books.jpg",
        imgFull: "img/folio/dog-loves-books-portrait.jpg",
        title: "Dog Loves Books",
        description:
          'Wrote and Composed Theme Tune: <div style="position: relative; height:100%;"><iframe width="560" height="315" src="https://www.youtube.com/embed/OwmTw6L5Pjw?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        cta: "Read More",
      },
    ],
    va: [
      {
        id: 2,
        new: false,
        url: "#",
        img: "img/folio/mim-mim.jpg",
        imgFull: "img/folio/mim-mim-portrait.jpg",
        title: "Kate & Mim-Mim",
        description: "Description",
        cta: "Read More",
      },
    ],
  };

  mounted(): void {
    this.changePortfolio(this.activePortfolio);
    this.activeItem = [this.portfolioItems];
  }

  public changePortfolio(type: string): void {
    this.activePortfolio = type;
    this.portfolioItems =
      type === "all"
        ? this.portfolio.mc.concat(this.portfolio.va)
        : this.portfolio[type];
  }

  public switchPortfolio(event: any): void {
    let type = event.target.getAttribute("data-type");

    if (!this.portfolio[type] && type !== "all") {
      type = "all";
    }
    this.changePortfolio(type);
  }

  public viewPortfolioItem(portfolioId: number): void {
    Object.keys(this.portfolioItems).forEach((key) => {
      if (this.portfolioItems[key].id === portfolioId) {
        this.activeItem = this.portfolioItems[key];
      }
    });

    this.$modal.show("portfolio-modal");
  }
}
